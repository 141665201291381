'use strict';

/**
 * Library jQuery
 * @module
 */

import $ from 'jquery';

window.$ = window.jQuery = $;
