'use strict';

// ----------------------------------------
// $.fn create jQuery function
// ----------------------------------------
const ellipsizeUtils = {
	dottedFromHiddenText: '...',
	callback: (block) => {
		let $block = $(block);
		let _countLine = $block.data('line');

		if (!$block.data('default')) {
			$block.data('default', block.innerHTML);
		}

		block.innerHTML = $block.data('default');
		$block.css('overflow', 'hidden');

		let _fs = +($block.css('font-size')).slice(0, -2);
		let _lh = +($block.css('line-height')).slice(0, -2);
		let maxInterval = 100;
		let _mH = (_fs > _lh) ? _fs * _countLine : _lh * _countLine;

		$block.css({
			'max-height': _mH,
			// 'min-height': _mH
		});

		if (block.scrollHeight > Math.ceil(_mH) + 1) {
			block.innerHTML = block.innerHTML.slice(0, -3) + ellipsizeUtils.dottedFromHiddenText;
		}

		let wordArray = block.innerHTML.split(' ');
		while (block.scrollHeight > Math.ceil(_mH) + 1 && maxInterval) {
			wordArray.pop();
			maxInterval--;
			block.innerHTML = wordArray.join(' ').slice(0, -3) + ellipsizeUtils.dottedFromHiddenText;
		}
	}
};

window.jQuery.fn.ellipsize = function (method) {
	// Methods
	if (typeof method === 'string') {
		if (method === 'reInit') {
			this.each((index, block) => {
				ellipsizeUtils.callback(block);
			});
		} else {
			console.warn('Method "' + method + '" does not exist.');
		}
		return this;
	}

	this.each((index, block) => {
		ellipsizeUtils.callback(block);
	});
};

let timeoutEllipsize = setTimeout(function () {
	clearTimeout(timeoutEllipsize);
	let ellipsizes = $('[data-ellipsize]:visible');
	if (ellipsizes.length) {
		ellipsizes.ellipsize();
	}
}, 300);
