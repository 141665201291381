'use strict';

/**
 * Promise polyfill
 * @see {@link https://www.npmjs.com/package/es6-promise-polyfill}
 * @module
 */

import {Promise} from 'es6-promise-polyfill';

window.Promise = window.Promise || Promise;
