'use strict';

import {ModulesImports} from '#/modules-imports';
import isObject from 'lodash/isObject';

import('core-js/fn/symbol/async-iterator');
import('regenerator-runtime/runtime');

/**
 * Processing server responses based on plugin sweetalert2
 * @see https://sweetalert2.github.io/
 * */

const PLUGIN_TYPE = 'swal';

let {mfpAdjust, messages} = window.gData;
let defaultSettings = {};

switch (PLUGIN_TYPE) {
	case 'swal':
		defaultSettings = {
			confirmButtonColor: '#313131',
			cancelButtonColor: '#e3050f'
		};
		break;
	default:
		break;
}

class ResponseActions {
	constructor (response) {
		this.response = (isObject(response) && !isObjectEmpty(response)) ? response : null;
	}

	/**
	 * Run process constructor data
	 * @public
	 * */
	process () {
		if (!this.response) {
			return new Promise((resolve) => {
				resolve();
			});
		}

		let response = this.response;
		let finalActions = (response) => {
			if (response.reload) {
				return window.location.reload();
			}

			if (response.redirect) {
				return (window.location.href = response.redirect);
			}

			if (
				response.magnific &&
				response.magnific === 'close' &&
				$.magnificPopup &&
				$.magnificPopup.instance.isOpen
			) {
				$.magnificPopup.close();
			}
		};

		if (response.magnific && response.magnific.url) {
			ResponseActions.fireAjaxPopup(response.magnific);
		}

		if (this.form) {
			if (response.reset) {
				if (Array.isArray(response.reset) && response.reset.length) {
					response.reset.forEach(name => {
						let selectorsByName = this.form.querySelectorAll(`[name='${name}]'`);

						for (let i = 0; i < selectorsByName.length; i++) {
							let selector = selectorsByName[i];

							selector[i].value = '';

							if (selector[i].tagName.toLowerCase() === 'select') {
								$(selector[i]).trigger('change.select2');
							}
						}
					});
				} else {
					this.form.reset();

					let selectors = this.form.querySelectorAll('select');

					if (selectors.length) {
						selectors.forEach(selector => {
							if (selector.tagName.toLowerCase() === 'select') {
								$(selector).trigger('change.select2');
							}
						});
					}
				}
			}
		}

		if (response.notifications && response.notifications.length) {
			return ResponseActions.showNotices(response.notifications).then(() => {
				finalActions(response);
			});
		} else {
			finalActions(response);
		}

		// laravel default messages
		if (response.errors && !isObjectEmpty(response.errors)) {
			let html = '';

			for (let k in response.errors) {
				if (response.errors.hasOwnProperty(k)) {
					let errors = response.errors[k];

					for (let i = 0; i < errors.length; i++) {
						html += `<p><strong>${errors[i]}</strong></p>`;
					}
				}
			}

			let title = '';

			if (response.message) {
				title = ResponseActions.getTranslate(response.message);
			}

			return ResponseActions.showNotices([{
				title: title,
				type: 'error',
				toast: true,
				html: html,
				position: 'top-right',
				timer: 5000
			}]);
		} else if (response.message) {
			return ResponseActions.showNotice(response.message);
		}

		return new Promise((resolve) => {
			resolve();
		});
	}

	/**
	 * Add form to instance class
	 * @param {Element} form
	 * @return {this}
	 * @public
	 * */
	setForm (form) {
		this.form = form;

		return this;
	}

	/**
	 * Add params for constructor data
	 * @param {object} obj
	 * @return {this}
	 * @public
	 * */
	extendData (obj) {
		this.response = $.extend(true, this.response, obj);

		return this;
	}

	/**
	 * @param {object} data
	 * @return {undefined}
	 * @static
	 * */
	static fireAjaxPopup (data) {
		if ($.magnificPopup && $.magnificPopup.instance.isOpen) {
			$.magnificPopup.close();
		}

		setTimeout(() => {
			$.magnificPopup.open({
				ajax: {
					settings: {
						url: data.url,
						data: data.data || {}
					}
				},
				items: {
					src: data.url
				},
				type: 'ajax',
				mainClass: `mfp-animate-zoom-in ${mfpAdjust.classes && mfpAdjust.classes.length ? mfpAdjust.classes.join(' ') : ''}`,
				removalDelay: 300,
				closeMarkup: mfpAdjust.closeButton,
				callbacks: {
					ajaxContentAdded () {
						let $container = this.contentContainer || null;

						if ($container) {
							ModulesImports.instance().importAll($container);
						}
					}
				}
			});
		}, 300);
	}

	/**
	 * @param {string|Object} title
	 * @param {string} text
	 * @param {string} type
	 * @return {promise|boolean}
	 * @static
	 * */
	static showNotice (title, text = '', type = '') {
		if (isObject(arguments[0])) {
			if (isObjectEmpty(arguments[0])) {
				return new Promise((resolve) => {
					resolve();
				});
			}

			return ResponseActions.getPlugin().then((plugin) => {
				return plugin($.extend({}, defaultSettings, arguments[0]));
			});
		}

		if (typeof title !== 'string') return false;

		title = ResponseActions.getTranslate(title);

		return ResponseActions.showNotices([
			{
				title: title,
				text: text,
				type: type,
				toast: true,
				position: 'top-end',
				timer: 3500
			}
		]);
	}

	/**
	 * Get translate messages
	 *
	 * @param {string} message - laravel server message
	 * @return {string} - translate message
	 * @static
	 * */
	static getTranslate (message) {
		if (messages) {
			if (message in messages) {
				return messages[message];
			}
		}

		return message;
	}

	/**
	 * Showed queue messages.
	 *
	 * @param {array} notices - array setting messages
	 * @return {promise}
	 * @static
	 * */
	static showNotices (notices = []) {
		if (!notices.length) {
			return new Promise((resolve) => {
				resolve();
			});
		}

		return ResponseActions.getPlugin().then((plugin) => {
			async function show () {
				for (let i = 0; i < notices.length; i++) {
					await plugin($.extend({}, defaultSettings, ResponseActions.modifyParams(notices[i]))).then(() => {
						// Crutch action on order page in notification message if user already registration and exist email
						let authOrderButtonTab = document.querySelector('[data-wstabs-ns="ord-user"][data-wstabs-button="1"]');
						if (authOrderButtonTab) {
							$(authOrderButtonTab).trigger('click');
						}
					});
				}
			}

			return show();
		});
	}

	/**
	 * Modify params from server depending on the plugin
	 *
	 * @param {object} setting - object params for plugin
	 * @return {object}
	 * @static
	 * */
	static modifyParams (setting) {
		switch (PLUGIN_TYPE) {
			case 'swal':
				for (let k in setting) {
					if (setting.hasOwnProperty(k)) {
						if (k === 'time') {
							setting['timer'] = setting[k];
							delete setting[k];
						}
					}
				}
				break;
			default:
				break;
		}

		return setting;
	}

	/**
	 * Webpack dynamic import plugins
	 *
	 * @return {promise} with function plugin
	 * @static
	 * */
	static getPlugin () {
		return import(/* webpackChunkName: 'sweetalert2' */ 'sweetalert2').then(plugin => {
			return plugin.default;
		});
	}
}

// Utils
function isObjectEmpty (obj) {
	let k;
	for (k in obj) return false;
	return true;
}

// Export
export default ResponseActions;
