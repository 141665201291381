'use strict';

import {ModuleDynamicImport as ModulesImports} from 'module-dynamic-import';

ModulesImports.create({
	PromiseFn: moduleFile => import('./_modules-imports/' + moduleFile),
	debug: IS_DEVELOP,
	modules: {
		formValidation: {
			moduleFile: 'form-validation-import',
			filterSelector: 'form'
		},
		lozad: {
			moduleFile: 'lozad-import',
			filterSelector: '[data-lozad], picture, video'
		},
		sliders: {
			moduleFile: 'sliders-import',
			filterSelector: '[data-slider]'
		},
		showMore: {
			moduleFile: 'show-more-import',
			filterSelector: '[data-more]'
		},
		placesDropdown: {
			moduleFile: 'places-dropdown-import',
			filterSelector: '[data-places-dropdown]'
		},
		accordion: {
			moduleFile: 'accordion-import',
			filterSelector: '[data-accordion]'
		},
		toggleHeight: {
			moduleFile: 'toggle-height-import',
			filterSelector: '[data-toggle-unit]'
		},
		magnificPopup: {
			moduleFile: 'magnific-popup-import',
			filterSelector: '[data-mfp]'
		},
		socialShare: {
			moduleFile: 'social-share-import',
			filterSelector: '[data-share]'
		},
		wysiwygMedia: {
			moduleFile: 'wysiwyg-media-import',
			filterSelector: '[data-wrap-media]'
		},
		draggableTable: {
			moduleFile: 'draggable-table-import',
			filterSelector: '[data-draggable-table]'
		},
		select2: {
			moduleFile: 'select2-import',
			filterSelector: '[data-s2s]'
			// importCondition () {	return window.Modernizr.touchevents;}
		},
		lettering: {
			moduleFile: 'lettering-import',
			filterSelector: '[data-lettering]'
		},
		lightGallery: {
			moduleFile: 'lightgallery-import',
			filterSelector: '[data-lgallery]'
		},
		phonemask: {
			moduleFile: 'phonemask-import',
			filterSelector: '[data-phonemask]'
		},
		youtube: {
			moduleFile: 'youtube-import',
			filterSelector: '[data-youtube]',
			importCondition () { return !window.Modernizr.analyzer; }
		},
		readmore: {
			moduleFile: 'readmore-import',
			filterSelector: '[data-readmore]'
		},
		search: {
			moduleFile: 'search-import',
			filterSelector: '[data-form-search]'
		},
		'review-more': {
			moduleFile: 'review-more-import',
			filterSelector: '[data-reviews]'
		}
	}
});

export {ModulesImports};
