'use strict';

import {mfpAjax} from '../magnific-popup/mfp-types';
import debounce from 'lodash/debounce';

class MMenu {
	constructor (selector = '[data-m-menu]') {
		if (typeof selector !== 'string') {
			return this;
		}

		this.$selector = $(selector);

		if (!this.$selector.length) {
			return this;
		}

		this.navbarsTop = $('[data-m-menu-navbars-top]', this.$selector);
		this.navbarsBottom = $('[data-m-menu-navbars-bottom]', this.$selector);
		this.iconbarTop = $('[data-m-menu-iconbar-top]', this.$selector);
		this.iconbarBottom = $('[data-m-menu-iconbar-bottom]', this.$selector);
		this.mmenuContainer = $('[data-m-menu-container]', this.$selector);

		this.breakpoint = 1025;
		this.DOMSettings = this.getDOMSettings();
		this.settings = this.setSettings(this.DOMSettings);
		this.loaded = false;
		this.mmApi = {};
		this.load();
	}

	getDOMSettings () {
		let {
			type = 'main',
			settings = {}
		} = this.$selector.data('m-menu') || {};

		this.mmenuContainer.attr('data-m-key', type);
		this.menuType = type;

		return settings;
	}

	load () {
		if (window.innerWidth < this.breakpoint && !this.loaded) {
			MMenu.loadPlugin().then(() => {
				this.loaded = !this.loaded;
				this.init();
			});
		}

		window.addEventListener('resize', debounce(() => {
			if (window.innerWidth < this.breakpoint && !this.loaded) {
				MMenu.loadPlugin().then(() => {
					this.loaded = !this.loaded;
					this.init();
				});
			}
		}, 200), false);
	}

	init () {
		const {
			options = {},
			configuration = {}
		} = this.settings;

		this.mmenuContainer.mmenu(options, configuration);

		this.mmApi = this.mmenuContainer.data('mmenu');

		this.event.on('click', () => {
			if (this.event.hasClass('is-active')) {
				this.mmApi.close();
			} else {
				this.mmApi.open();
			}
		});

		this.mmApi.bind('open:finish', () => {
			setTimeout(() => {
				this.event.addClass('is-active');
				window.dispatchEvent(new Event('resize'));
			}, 50);
		});

		this.mmApi.bind('close:finish', () => {
			setTimeout(() => {
				this.event.removeClass('is-active');
				window.dispatchEvent(new Event('resize'));
			}, 50);
		});

		window.addEventListener('resize', () => {
			if (window.innerWidth > 1024) {
				this.mmApi.close();
			}
		}, false);

		const $mfp = $('[data-mfp]', this.mmenuContainer);

		if ($mfp.length) {
			setTimeout(() => mfpAjax($mfp), 100);
		}

		/*
		* Add custom global event bind to window gData object for action close mmenu
		* */
		$(window.gData).on('close.mmenu', () => {
			this.mmApi.close();
		});
	}

	get event () {
		return $(`[data-m-menu-event='${this.menuType}']`);
	}

	setSettings (options) {
		if (Object.prototype.toString.call(options) !== '[object Object]') {
			console.warn('Dom setting must be object!');
			options = {};
		}

		if (this.navbarsTop.length) {
			if (!options.options.navbars) {
				options.options.navbars = [];
			}
			options.options.navbars.push({
				'position': 'top',
				'height': 1,
				'content': [
					'close',
					this.navbarsTop[0].innerHTML
				]
			});
		}

		if (this.navbarsBottom.length) {
			if (!options.options.navbars) {
				options.options.navbars = [];
			}
			options.options.navbars.push({
				'position': 'bottom',
				'content': [this.navbarsBottom[0].innerHTML]
			});
		}

		if (this.iconbarTop.length || this.iconbarBottom.length) {
			if (!options.options.iconbar) {
				options.options.iconbar = {};
			}

			options.options.iconbar.add = true;
		}

		if (this.iconbarTop.length) {
			options.options.iconbar.top = this.iconbarTop[0].innerHTML.split('<br>');
		}

		if (this.iconbarBottom.length) {
			options.options.iconbar.bottom = this.iconbarBottom[0].innerHTML.split('<br>');
		}

		return $.extend(true, {}, MMenu._defaultSetting, options);
	}

	static loadPlugin () {
		return import(/* webpackChunkName: "jquery.mmenu" */ './import');
	}

	static get _defaultSetting () {
		return {
			options: {
				extensions: [
					'multiline',
					'position-right',
					'fx-menu-slide',
					'pagedim-black',
					'theme-white',
					'position-front'
				],
				navbar: {
					title: ''
				},
				navbars: []
			},
			configuration: {
				offCanvas: {
					page: {
						selector: '.page'
					}
				},
				classNames: {
					fixedElements: {
						fixed: 'mm-fixed'
					}
				},
				language: document.documentElement.lang
			}
		};
	}
}

export default MMenu;
