'use strict';
if (!window.Modernizr.analyzer) {
	window.addEventListener('load', function () {
		// Проверяем поддерживает ли браузер тег <template>
		// проверив наличие аттрибута content у элемента template
		if ('content' in document.createElement('template')) {
			setTimeout(() => {
				let templates = $(document).find('[id*=wezomSiteSeo]');
				if (templates.length) {
					$.each(templates, (i, t) => {
						$(t).after(t.content);
					});
				}
			}, 10);
		} else {
			console.warn('Tег <template> не поддерживатся браузером. Seo скрипты не будут добавленны');
		}
	});
}
