'use strict';

/**
 * @file Main initialization file
 */

import './_vendors/font-face-observer';
import './_vendors/promise-polyfill';
import './_vendors/jquery';
import './_vendors/ellipsize';
import './_vendors/seo-templates';
import debounce from 'lodash/debounce';

import {ModulesImports} from './modules-imports';
import wsTabs from 'wezom-standard-tabs';
import mobileScrollHeader from './_modules/mobile-scroll-header';
import cookieData from './_modules/cookie-data';
import MMenu from './_modules/mmenu';
import inviewInit from '#/_modules/inview';
import ResponseActions from './_modules/ResponseActions';
import ReadMore from './_modules/readmore.js';

import 'readmore-js';

window.ResponseActions = ResponseActions;

document.addEventListener('DOMContentLoaded', () => {
	document.body.classList.add('dom-loaded');

	let mainVideo = document.querySelector('#main-video-element');
	if (mainVideo) {
		document.querySelector('#main-video-element').defaultPlaybackRate = 0.75;
		document.querySelector('#main-video-element').playbackRate = 0.75;
		document.querySelector('#main-video-element').play();
	}

	let needHelp = document.querySelector('.need-help');
	let needHelpQuestion = document.querySelector('.need-help__question');
	let needHelpClose = document.querySelector('.need-help__close');
	if (needHelp && needHelpClose && needHelpQuestion) {
		needHelpClose.addEventListener('click', () => {
			needHelp.classList.toggle('is-hidden');
			needHelpQuestion.classList.remove('is-hidden');
		});
	}

	let mainMenu = document.querySelector('.main-menu');
	let menuContainer = document.querySelector('.c-main-menu');
	let burgers = document.querySelectorAll('.menu-burger');

	burgers.forEach(burger => {
		burger.addEventListener('click', event => {
			event.preventDefault();
			event.stopPropagation();
			burgers.forEach(_burger => _burger.classList.toggle('active'));

			mainMenu.classList.toggle('active');

			menuContainer.classList.toggle('active');
		});
	});

	menuContainer.addEventListener('click', () => {
		burgers.forEach(_burger => _burger.classList.toggle('active'));

		mainMenu.classList.toggle('active');
		menuContainer.classList.toggle('active');
	});

	// SEARCH
	let searchHeader = document.querySelector('.header:not(.header--home) .search-header');
	let searchHeaderIcon = document.querySelector('.header:not(.header--home) .search-header__icon');
	searchHeaderIcon.addEventListener('click', (event) => {
		event.preventDefault();

		searchHeader.classList.toggle('active');
		searchHeader.querySelector('input').focus();
	});
	searchHeader.querySelector('button').addEventListener('click', (event) => {
		event.preventDefault();
		searchHeader.classList.toggle('active');
	});

	let searchHeaderHome = document.querySelector('.header.header--home .search-header');
	let searchHeaderIconHome = document.querySelector('.header.header--home .search-header__icon');
	if (searchHeaderHome && searchHeaderIconHome) {
		searchHeaderIconHome.addEventListener('click', (event) => {
			event.preventDefault();

			searchHeaderHome.classList.toggle('active');
			searchHeaderHome.querySelector('input').focus();
		});
		searchHeaderHome.querySelector('button').addEventListener('click', (event) => {
			event.preventDefault();
			searchHeaderHome.classList.toggle('active');
		});
	}
});

window.addEventListener('load', () => {
	if (!window.Modernizr.analyzer) {
		inviewInit($(document));
		(() => new MMenu())();
		$('[data-ellipsize]').ellipsize();
	}
});

window.jQuery(() => {
	$.ajaxSetup({
		beforeSend: function (xhr, url) {
			if (!url.crossDomain) {
				xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
			}
		},
		headers: {
			'Content-Language': document.documentElement.lang
		}
	});

	$.easing['easeOutCirc'] = (x, t, b, c, d) => {
		return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
	};

	ReadMore($('.seo-text'));
	ReadMore($('.seo-text-a'), 300);

	// $('.seo-text-a').readmore({
	// 	speed: 1075,
	// 	// embedCSS: false,
	// 	collapsedHeight: 300,
	// 	// moreLink: $('[data-readmore-button-open]').html(),
	// 	// lessLink: $('[data-readmore-button-close]').html()
	// });

	wsTabs.init();

	$(document).on('click', `[data-wstabs-button]`, function () {
		let key = $(this).data('wstabs-button');
		$(`[data-wstabs-button="${key}"]`).addClass('is-active');
		$(`[data-wstabs-block="${key}"]`).find('[data-draggable-table]').trigger('resize');
	});

	mobileScrollHeader();

	const $root = $('html, body, .page');
	const $window = $(window);

	// Search action
	(() => {
		const htmlEl = document.documentElement;
		const search = {
			selector: 'shown-search',
			searchInput: '[data-search-input]',
			buttonMobileSearch: '[data-search-button="mobile"]',
			buttonCloseSearch: '[data-search-button="close"]',
			close () {
				htmlEl.classList.remove(search.selector);
			}
		};

		$(document).on('click', search.buttonMobileSearch, (event) => {
			event.preventDefault();
			if (htmlEl.classList.contains(search.selector)) {
				htmlEl.classList.remove(search.selector);
			} else {
				htmlEl.classList.add(search.selector);
				let input = document.querySelector(search.searchInput);
				if (input && window.Modernizr.mobiledevice) {
					let t = setTimeout(() => {
						$(search.searchInput, $(document)).focus();
						clearTimeout(t);
					}, 300);
				}
			}
		})
			.on('click', search.buttonCloseSearch, () => search.close())
			.keyup(function (event) {
				if (event.key === 'Escape') {
					search.close();
				}
			});
	})();

	(() => {
		$('[data-swap-title-container]').html($('[data-swap-title]').html());
	})();

	// Button scroll top
	(() => {
		const checkClass = ($buttonUp) => {
			if ($window.scrollTop() > $window.height()) {
				$buttonUp.addClass('is-show');
			} else {
				$buttonUp.removeClass('is-show');
			}
		};

		const checkHeader = ($headerMain) => {
			if ($window.scrollTop() > $window.height()) {
				$headerMain.removeClass('is-show');
			} else {
				$headerMain.addClass('is-show');
			}
		};

		const headerShadow = ($header) => {
			if ($window.scrollTop() > 0) {
				$header.addClass('header--shadow');
			} else {
				$header.removeClass('header--shadow');
			}
		};

		document.querySelector('.scroll-up-btn').addEventListener('click', () => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		});

		let buttons = $('[data-scroll-window]');

		let headerHome = $('.header--home');
		if (headerHome) {
			$window.scroll(function () {
				checkHeader($(headerHome));
			});
		}

		let headers = $('.header');
		if (headers) {
			$.each(headers, (i, header) => {
				$window.scroll(function () {
					headerShadow($(header));
				});
			});
		}

		let ups = $('.scroll-up-btn');
		$.each(ups, (i, up) => {
			$window.scroll(function () {
				checkClass($(up));
			});

			checkClass($(up));
		});

		$.each(buttons, (i, button) => {
			let target = button.dataset.scrollWindow;
			switch (target) {
				case 'up':
					// $(button).on('click', () => {
					// 	// $root.stop().animate({
					// 	// 	scrollTop: 0
					// 	// }, 500, 'easeOutCirc');
					// 	window.scrollTo({
					// 		top: 0,
					// 		behavior: 'smooth'
					// 	});
					// 	// $root.animate({
					// 	// 	scrollTop: 0
					// 	// }, 500, 'easeOutCirc');
					// });

					$window.scroll(function () {
						checkClass($(button));
					});

					checkClass($(button));
					break;
				default:
					$(button).on('click', () => {
						$root.stop().animate({
							scrollTop: $(target).length ? $(target)[0].offsetTop : 0
						}, 500, 'easeOutCirc');
					});
			}
		});
	})();

	ModulesImports.instance().importAll();
	if (!window.Modernizr.analyzer) {
		cookieData.askUsage();
	}

	(() => {
		let tables = $('table.table-design');

		$.each(tables, (i, table) => {
			$(table).find('tr').each(function (rowIndex, tr) {
				let parentTagName = $(this).parent().get(0).tagName;
				parentTagName = parentTagName.toLowerCase();
				if (parentTagName !== 'thead') {
					$(tr).addClass('rov-' + rowIndex);
					let tdList = $(this).children();
					$(tdList).each(function (tdIndex, td) {
						$(td).addClass('coll-' + tdIndex);
					});
				}
			});
			$(table).find('td').mouseover(function () {
				let tdClassName = this.className;
				if (tdClassName) {
					let trClassName = $(this).parent().get(0).className;
					$(table).find('.' + tdClassName).addClass('cell-hover');
					$(table).find('.' + trClassName).addClass('cell-hover');
					$(this).addClass('cell-focus');
				}
			}).mouseout(function () {
				$(table).find('tr').removeClass('cell-hover');
				$(table).find('td').removeClass('cell-hover');
				$(this).removeClass('cell-focus');
			});
		});
	})();
});

function _resizeF () {
	let ellipsizes = window.$('[data-ellipsize]:not([data-ellipsize-slick])');
	if (ellipsizes.length && !window.Modernizr.analyzer) {
		ellipsizes.ellipsize('reInit');
	}

	let $thumbs = window.$('.prod-thumbs')[0];
	const $slider = window.$('.prod-slider')[0];
	if ($thumbs && $slider) {
		const {height} = $slider.getBoundingClientRect();
		$thumbs.css('max-height', height);
	}
}

window.addEventListener('resize', debounce(_resizeF, 100));
window.addEventListener('orientationchange', debounce(_resizeF, 101));

if (IS_PRODUCTION) {
	import('./_modules/wezom-log');
}
