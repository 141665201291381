'use strict';

import debounce from 'lodash/debounce';

export default function mobileScrollHeader () {
	const $header = $('[data-mheader]');
	const $window = $(window);

	const header = {
		range: 30,
		hiddenClass: 'scrolling-down',
		subClass: 'scrolling-sub',
		isInit: false,
		isState: '',

		get currentScroll () {
			return $window.scrollTop();
		},

		get height () {
			return $header.outerHeight() + this.range;
		},

		up () {
			$header.addClass(this.hiddenClass);
			header.isState = 'hidden';
		},

		showSub () {
			$header.addClass(this.subClass);
		},

		down () {
			$header.removeClass(this.hiddenClass);
			header.isState = 'visible';
		},

		hideSub () {
			$header.removeClass(this.subClass);
		},

		onScroll (initial) {
			const scroll = header.currentScroll;

			if (scroll > header.height) {
				if (scroll < header.scroll) {
					header.down();
				} else if ((initial === true) || (scroll + header.range > header.scroll)) {
					header.up();
				}
			} else if (scroll < header.height && header.isState === 'hidden') { // Fixing IOS
				header.down();
			}

			if (scroll > 30) {
				header.showSub();
			} else {
				header.hideSub();
			}

			header.scroll = scroll;
		},

		init () {
			if ($window.outerWidth() > 1024) {
				return;
			}

			header.scroll = header.currentScroll;
			header.onScroll(true);
			$window.on('scroll', header.onScroll);
			header.isInit = !header.isInit;
		},

		destroy () {
			header.down();
			$window.off('scroll', header.onScroll);
			header.isInit = !header.isInit;
		},

		resize () {
			if ($window.outerWidth() > 1024 && header.isInit) {
				header.destroy();
			} else {
				if (!header.isInit) {
					header.init();
				}
			}
		}
	};

	header.init();
	$window.on('resize', debounce(header.resize, 10));
}
