'use strict';

import {ModulesImports} from '#/modules-imports/';

/**
 * @param {JQuery} $element
 */
export function mfpAjax ($element) {
	$element.magnificPopup({
		type: 'ajax',
		mainClass: 'mfp-animate-zoom-in mfp-customize',
		removalDelay: 300,
		tLoading: '<span class="mfp-preloader-view"></span>',
		closeMarkup: window.gData.mfpAdjust.closeButton,
		fixedBgPos: true,
		fixedContentPos: true,
		closeOnContentClick: false,
		callbacks: {
			elementParse (item) {
				const {
					url,
					type = 'GET',
					param: data = {}
				} = item.el.data();

				this.st.ajax.settings = {url, type, data};
			},
			ajaxContentAdded () {
				let $container = this.contentContainer || [];

				if ($container) {
					ModulesImports.instance().importAll($container);
				}
			},
			open () {
				$(window.gData).trigger('close.mmenu');

				if (document.documentElement.classList.contains('mm-wrapper_opening')) {
					document.documentElement.style.marginRight = 0;
				}
			},
			close () {
				if ($element.filter('[data-slider-resize]').length) {
					window.dispatchEvent(new Event('resize'));
				}
			}
		}
	});
}

/**
 * @param {JQuery} $element
 */
export function mfpIframe ($element) {
	$element.magnificPopup({
		type: 'iframe',
		removalDelay: 300,
		mainClass: 'mfp-animate-zoom-in mfp-customize',
		closeMarkup: window.gData.mfpAdjust.closeButton,
		closeBtnInside: true,
		fixedBgPos: true,
		fixedContentPos: true,
		closeOnContentClick: false
	});
}

/**
 * @param {JQuery} $element
 */
export function mfpInline ($element) {
	$element.magnificPopup({
		type: 'inline',
		removalDelay: 300,
		mainClass: 'mfp-animate-zoom-in mfp-customize',
		closeMarkup: window.gData.mfpAdjust.closeButton,
		closeBtnInside: true,
		fixedBgPos: true,
		fixedContentPos: true,
		closeOnContentClick: false
	});
}

/**
 * @param {JQuery} $element
 */
export function mfpGallery ($element) {
	$element.magnificPopup({
		type: 'image',
		removalDelay: 300,
		mainClass: 'mfp-animate-zoom-in mfp-customize',
		closeMarkup: window.gData.mfpAdjust.closeButton,
		delegate: '[data-mfp-src]',
		closeBtnInside: true,
		fixedBgPos: true,
		fixedContentPos: true,
		closeOnContentClick: false,
		gallery: {
			enabled: true,
			preload: [0, 2],
			navigateByImgClick: true,
			arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>'
		},
		callbacks: {
			elementParse (item) {
				if (/youtube|vimeo|maps\.google/.test(item.src)) {
					item.type = 'iframe';
				}
			}
		}
	});
}

/**
 * @param {JQuery} $element
 */
export function mfpImage ($element) {
	$element.magnificPopup({
		type: 'image',
		removalDelay: 300,
		mainClass: 'mfp-animate-zoom-in mfp-customize',
		closeMarkup: window.gData.mfpAdjust.closeButton,
		closeOnContentClick: true,
		closeBtnInside: true,
		fixedContentPos: true,
		fixedBgPos: true,
		image: {
			verticalFit: true
		}
	});
}
