import $ from 'jquery';
import 'readmore-js';

// ----------------------------------------
// Public
// ----------------------------------------

/**
 * @param {JQuery} $elements
 */
function init ($elements, height = 100) {
	$elements.each((i, el) => {
		const $element = $(el);
		$element.readmore({
			speed: 1075,
			collapsedHeight: height,
			moreLink: $('[data-readmore-button-open]').html(),
			lessLink: $('[data-readmore-button-close]').html()
		});
	});
}
// ----------------------------------------
// Exports
// ----------------------------------------

export default init;
