import 'custom-jquery-methods/fn/has-inited-key';
import 'jquery-inview/jquery.inview.min';

/**
 * @param {JQuery} $document
 * @sourceCode
 */
function inviewInit ($document) {
	const eventsNamespase = {
		onInview: 'on',
		oneInview: 'one',
		titleInview: 'one'
	};

	const events = {
		onInview: $('[data-on-inview]', $document),
		oneInview: $('[data-one-inview]', $document),
		titleInview: $('[data-title-inview]', $document)
	};

	let _f = (isInView, _config, $el) => {
		if (isInView) {
			if (_config.toggleClass) {
				if (_config.delay) {
					let t = setTimeout(() => {
						clearTimeout(t);
						$el.addClass(_config.toggleClass);
					}, _config.delay * (window.Modernizr.mobiledevice ? 10 : 100));
				} else {
					$el.addClass(_config.toggleClass);
				}
			}
		} else {
			if (_config.toggleClass) {
				$el.removeClass(_config.toggleClass);
			}
		}
	};

	Object.keys(events).map(function (objectKey, index) {
		let list = events[objectKey];

		$(list)[eventsNamespase[objectKey]]('inview', function (event, isInView) {
			let $el = $(event.currentTarget);
			let $elData = event.currentTarget.dataset[objectKey];

			try {
				$elData = JSON.parse($elData);
			} catch (e) {}

			let _config = $.extend(true, {
				type: 'on',
				toggleClass: 'is-show',
				delay: 1
			}, $elData);

			_f(isInView, _config, $el);
		});
	});
}

// ----------------------------------------
// Exports
// ----------------------------------------

export default inviewInit;
